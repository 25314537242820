import React from 'react';
import {dsnCN} from "../../../hooks/helper";

function MenuContent({className}) {
    const socialData = [
        {link: "https://www.facebook.com/Primetime-Media-261465490601912", name: "Facebook."},
        {link: "https://twitter.com/ptmediatv", name: "Twitter."},
        {link: "https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=false&heroEntityKey=urn%3Ali%3Aorganization%3A2878594&keywords=primetime%20media%20s.a.s.&origin=RICH_QUERY_TYPEAHEAD_HISTORY&position=0&searchId=461ba9b9-daa8-44c5-b769-c7e3ff8491c5&sid=%2CU%3A", name: "Linkedin."},
        {link: "https://vimeo.com/user33879570", name: "Vimeo."}
    ];
    return (
        <div className={dsnCN('container-content  d-flex flex-column justify-content-center', className)}>
            <div className="nav__info">
                <div className="nav-content">
                    <h5 className="sm-title-block mb-10">PRIMETIME MEDIA</h5>
                    <p>
                    Bogotá, Colombia <br/> Cra. 7 Bis A # 124-70, of. 708
                    </p>

                </div>
                <div className="nav-content mt-30">
                    <h5 className="sm-title-block mb-10">Contacto</h5>
                    <p className="links over-hidden mb-1">
                        <a className="link-hover" href="tel:+(57) (1) 9373010" data-hover-text="+(57) (1) 9373010">
                        BOGOTÁ +(57) (1) 9373010
                        </a><br></br>
                        <a className="link-hover" href="tel:+(1) (305) 9066532" data-hover-text="+(1) (305) 9066532">
                        MIAMI +(1) (305) 9066532
                        </a>
                    </p>
                    <br></br>
                </div>
                <div className="nav-content">
                    <h5 className="sm-title-block mb-10">Associated Company<br></br>BLUECLOUD MEDIA LLC</h5>
                    <p>
                    5220 S UNIVERSITY DR. DAVIE SUITE C-102 FL 33328
                    </p>

                </div>
                <div className="nav-content mt-30">
                    <h5 className="sm-title-block mb-10">Contacto</h5>
                    <p className="links over-hidden mb-1">
                        <a className="link-hover" href="tel:+(1) (678)7406656" data-hover-text="+(1) (678)7406656">
                        USA: +(1) (678)7406656
                        </a>
                    </p><br></br><br></br>
                    <p className="links over-hidden">
                        <a className="link-hover" href="mailto:asuntoslegales@ptmedia.tv"
                           data-hover-text="info@dsngrid.com">asuntoslegales@ptmedia.tv</a>
                    </p>
                </div>
            </div>
            <div className="nav-social nav-content mt-30">
                <div className="nav-social-inner p-relative">
                    <h5 className="sm-title-block mb-10">Follow us</h5>
                    <ul>
                        {socialData.map((item, index) =>
                            <li key={index}>
                                <a href={item.link} target="_blank" rel="nofollow noopener noreferrer">{item.name}</a>
                            </li>)}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default MenuContent;